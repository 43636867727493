<template>
  <ion-page>
    <ion-content>
      <div class="back-button">
        <ion-button @click="$router.go(-1)" >
          <ion-icon
              :icon="chevronBack"
          />
        </ion-button>
      </div>
      <div v-if="hasLoad" class="maxWidth">
        <ion-text style="display: flex; justify-content: center; align-items: baseline">
          <h1>{{ mission.title }} <span :class="userLight"></span></h1>
        </ion-text>
        <p style="margin: 0!important; color: black;">{{ mission.day }}</p>


        <ion-text v-if="mission.description || mission.pharmacyDescription">
          <p class="description">
            <span v-html="mission.description"/> <br/>
            <span v-html="mission.pharmacyDescription"/>
          </p>
        </ion-text>
        <div v-else style="margin: 30px 0 30px 0"/>

        <ion-card v-if="updateMode">
          <ion-card-content>
            <div style="display: flex; justify-content: space-around;">
              <ion-item slot="start"  lines="full"  style="width: 45%;">
                <ion-label class="label" position="floating">Début</ion-label>
                <ion-datetime displayFormat="HH:mm"
                              locale='fr-FR'
                              minuteValues="0,15,30,45"
                              show-default-buttons="true"
                              size="cover"
                              done-text="Confirmer"
                              cancel-text="Retour"
                              v-model="mission.beginTime"
                />
              </ion-item>
              <ion-item slot="end" style="width: 45%">
                <ion-label class="label" position="floating">Fin</ion-label>
                <ion-datetime displayFormat="HH:mm"
                              locale='fr-FR'
                              minuteValues="0,15,30,45"
                              show-default-buttons="true"
                              done-text="Confirmer"
                              cancel-text="Retour"
                              v-model="mission.endTime"
                />
              </ion-item>
            </div>
            <div style="display: flex; justify-content: space-around;">
              <ion-item slot="start" style="width: 45%;">
                <ion-label class="label" position="floating">Temps de repos</ion-label>
                <ion-datetime displayFormat="HH:mm"
                              locale='fr-FR'
                              hourValues="0,1,2"
                              minuteValues="0,15,30,45"
                              show-default-buttons="true"
                              size="cover"
                              done-text="Confirmer"
                              cancel-text="Retour"
                              v-model="mission.pauseHours"
                />
              </ion-item>
              <ion-item slot="start" style="width: 45%">
                <ion-label class="label ion-text-no-wrap" position="floating"> Prime journalière</ion-label>
                <ion-select interface="alert" placeholder="Veuillez choisir" v-model="mission.increase">
                  <ion-select-option v-for="n in 31" :key="n-1" :value="n-1">{{ n-1 }}€</ion-select-option>
                </ion-select>
              </ion-item>
            </div>

            <div class="checkboxes-container">
              <div class="checkbox-input">
                <ion-label position="fixed" type="dark" class="label" style="flex: auto; width: 9rem" >Horaire flexible ?</ion-label>
                <ion-checkbox v-model="mission.isFlexible" mode="ios"></ion-checkbox>
              </div>

              <div class="checkbox-input">
                <ion-label position="fixed" type="dark" class="label" style="flex: auto; width: 9rem" >Titulaire présent ?</ion-label>
                <ion-checkbox v-model="mission.ownerStatus" mode="ios"></ion-checkbox>
              </div>
            </div>

            <div>
              <ion-text>
                <p style="color: black; font-size: 20px;">Tarif actuel :
                  <span style="text-decoration: underline">{{ stringPricingToString(mission.pricing) }}</span>
                </p>
              </ion-text>
            </div>

            <hr/>
            <ion-item slot="start" style="width: 80%; margin: auto">
              <ion-label class="label ion-text-no-wrap" position="floating">Quelle typologie pour votre intérimaire</ion-label>
              <ion-select interface="alert" placeholder="Veuillez choisir" v-model="mission.grade">
                <ion-select-option value="pharmacien_substitute">Pharmacien</ion-select-option>
                <ion-select-option value="preparer">Préparateur</ion-select-option>
                <ion-select-option value="student">Étudiant</ion-select-option>
                <ion-select-option value="preparer_student">Préparateur ou Étudiant</ion-select-option>
                <ion-select-option value="pharmacien_preparer_substitute">Préparateur ou Pharmacien</ion-select-option>
                <ion-select-option value="pharmacien_preparer_student_substitute">Tous profil</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-card-content>
        </ion-card>
        <div style="display: flex; flex-direction: column; align-items: center; color: black" v-else>
          <h3 class="ion-text-left ">
            🕒 {{ mission.beginTime }} - {{ mission.endTime }}
          </h3>
          <h3 class="ion-text-left">☕ {{ mission.pauseHours }}h</h3>
        </div>
        <ion-card v-if="mission.user">
          <ion-card-header style="padding: 0">
            <ion-card-title>
              <h1 style="padding-bottom: 5px; border-bottom: 1px solid rgb(0,0,0); border-radius: 50%">Votre intérimaire ☺️</h1>
            </ion-card-title>
          </ion-card-header>
          <ion-card-content style="display: flex; justify-content: space-evenly">
            <ProfilePicture :user="mission.user"/>
            <ion-text style="text-align: left; color: black; width: 70%">
              <p style="font-size: 20px">{{ mission.user.firstName }} {{ mission.user.lastName }}<span v-if="mission.user.age">, {{ mission.user.age}} ans</span></p>
              <p><span class="cViolet">Statut :</span> {{ mission.user.grade }} </p>
              <p><span class="cViolet">Obtention du diplôme :</span> {{ yearGraduate }} </p>
              <p v-if="mission.user.level"><span class="cViolet">Niveau :</span> {{ mission.user.level }} </p>
              <p v-if="mission.user.speciality"><span class="cViolet">Compétences :</span> {{ mission.user.speciality }} </p>
              <p v-if="mission.user.personality"><span class="cViolet">Personnalité :</span> {{ mission.user.personality }} </p>
              <p v-if="mission.user.school"><span class="cViolet">École :</span> {{ mission.user.school }} </p>
              <p v-if="mission.user.likes > 0" style="display: flex; align-items: center; font-size: 18px">
                {{mission.user.likes}}
                <ion-icon color="danger" :icon="heartSharp" style="font-size: 16px"/>
                de titulaires
              </p>
            </ion-text>
          </ion-card-content>
        </ion-card>
        <div v-else>
          <ion-card v-if="!updateMode">
            <ion-card-header style="padding: 0">
              <ion-card-title>
                <h1 class="ion-margin-start">Reherche en cours...</h1>
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p style="color: black; text-align: left">Pour augmenter vos chances de trouver rapidement votre intérimaire, voici quelques astuces :</p>
              <ion-text color="primary">
                <ul style="text-align: left" >
                  <li>Vous pouvez majorer votre offre</li>
                  <li v-if="mission.pricing !== 'premium'">Vous pouvez mettre à jour la tarification</li>
                  <li>Vous pouvez augmenter la plage horaire</li>
                  <li>Vous pouvez rendre flexible votre offre</li>
                </ul>
              </ion-text>
            </ion-card-content>
          </ion-card>
          <div>

            <ion-button v-if="!updateMode" class="bottom-button" @click="invertUpdated">
              Modifier la mission
            </ion-button>
            <div v-else class="bottom-button">
              <ion-button v-if="mission.pricing !== 'premium'" @click="upgradePricing">
                Basculer le tarif en {{ stringPricingNextPricing(mission.pricing) }}
              </ion-button>
              <ion-button @click="updateMission" color="success">
                Enregistrer
              </ion-button>
              <ion-button @click="invertUpdated" color="secondary">
                Annuler
              </ion-button>
            </div>
          </div>
        </div>

      </div>
      <div v-else>
        <ion-text class="ion-text-center">
          <h1>Chargement en cours... ⏳</h1>
        </ion-text>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import store from "@/store";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  toastController
} from "@ionic/vue";
import {getMissionInfo, updateMission} from "@/services/api";
import {
  calendarOutline,
  chevronBack,
  closeOutline,
  documentText,
  heartSharp,
  informationCircleOutline,
  medkitOutline,
  navigateOutline,
  star,
  starOutline,
  personCircleSharp
} from "ionicons/icons";
import {snake} from "snake-camel";
import {stringPricingNextPricing, stringPricingToNumber, stringPricingToString} from "@/services/utils";
import {Dialog} from "@capacitor/dialog";
import ProfilePicture from "@/components/ProfilePicture";

export default {
  name: "Mission",
  components: {
    ProfilePicture,
    IonPage,
    IonContent,
    IonIcon,
    IonText,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonDatetime,
    IonLabel,
    IonButton,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
  },
  data() {

    return {
      hasLoad: false,
      mission: {},
      updateMode: false,
    };
  },
  setup() {
    return {
      navigateOutline,
      medkitOutline,
      documentText,
      informationCircleOutline,
      calendarOutline,
      personCircleSharp,
      starOutline,
      star,
      chevronBack,
      closeOutline,
      heartSharp,
      stringPricingToString,
      stringPricingNextPricing,
      stringPricingToNumber,
    };
  },

  ionViewDidEnter() {
    const { id } = store.getters.currentMission;
    console.log(id)
    getMissionInfo(id).then((response) => {
      this.setMission(response)
      console.log(response)
    })
  },
  ionViewWillLeave() {
    this.hasLoad = false;
    this.updateMode = false;
  },
  computed: {
    userLight() {
      if (this.hasUser) {
        return "bullet bullet--green";
      }
      return 'bullet';
    },
    workedHours() {
      if (!this.hasUser || this.mission.user?.workedHours === 0) {
        return 'Pas encore de mission';
      } else {
        return `+ de ${this.mission.user.workedHours} heures`;
      }
    },
    yearGraduate() {
      if (!this.hasUser || !this.mission.user.yearGraduate) {
        return 'N/C';
      } else if (this.mission.user.grade === 'student') {
        return 'N/A (en cours)';
      }
      return this.mission.user.yearGraduate;
    }
  },

  methods: {
    setMission(data) {
      this.mission = data;
      this.hasLoad = true;
      this.mission.ownerStatus = data.ownerStatus === 'present';
      this.hasUser = !!data.user
    },

    invertUpdated() {
      this.updateMode = !this.updateMode;
    },

    updateMission() {
      const { beginTime, endTime, pauseHours, increase, isFlexible, ownerStatus, grade } = this.mission;
      const params = {
        beginTime, endTime, pauseHours, increase, isFlexible,
        ownerStatus: ownerStatus ? 'present' : 'replacement',
        grade: snake(grade) };
      updateMission(this.mission.id, params).then((response) => {
        this.setMission(response);
        this.invertUpdated();
        this.displayMissionUpdated();
      });
    },

    upgradePricing() {
      this.showConfirmWantUpgradePricing().then(({value}) => {
        if (!value) {
          return;
        }
        const params = { pricing: stringPricingToNumber[this.mission.pricing]+1 };
        updateMission(this.mission.id, params).then((response) => {
          this.setMission(response);
          this.invertUpdated();
          this.displayMissionUpdated();
        });
      })
    },

    async showConfirmWantUpgradePricing() {
      return await Dialog.confirm({
        title: "Confirmer",
        message: "Êtes vous-sûr de passer au tarif supérieur ? Cette action est irréversible"
      });
    },

    async displayMissionUpdated() {
      const toast = await toastController.create({
        message: "Votre mission est mise à jour",
        duration: 4000,
        color: 'success'
      });
      return toast.present();
    }
  }
}

</script>

<style scoped>
.checkboxes-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0 12px;
}

.checkbox-input {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;

}

@media screen and (max-device-width:640px), screen and (max-width:640px) {
  .checkboxes-container {
    flex-direction: column;
    margin: 0;
  }
  .checkbox-input {
    margin: 10px 0 10px 0;
  }
}


.bottom-button {
  margin: 10px 0 60px 0;
}

.bullet{
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  background-color: #ffc107;
  display: inline-block;
}

.bullet--green{
  background-color: #4caf50;
}

.icon {
  color: #d56498;
  margin-right: 6px;
}

.description {
  width: 50%;
  color: var(--ion-color-secondary);
  border: 1px solid #80808042;
  border-radius: 13px;
  padding: .5rem;
  margin: auto auto 2rem;
}

.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100%;
  text-align: left;
}

@media screen and (max-device-width:640px), screen and (max-width:640px) {
  .description {
    width: 100%;
  }

  .back-button {
    position: relative;
  }


}

</style>
